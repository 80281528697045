.footer {
  --_paddings: 3rem;

  font-size: 1.5rem;
  color: var(--white);
  border-radius: var(--radius) var(--radius) 0 0;
  background: linear-gradient(
    90deg,
    #009ddc 0.09%,
    #009ddc 10.09%,
    #2ba5ac 30.09%,
    #54ac7f 53.09%,
    #72b25e 72.09%,
    #85b54a 100.09%
  );

  margin-top: 18rem;
  padding-top: var(--_paddings);
  padding-bottom: var(--_paddings);

  @media (--media-mobile) {
    --_paddings: 2rem;

    font-size: 1.25rem;
    margin-top: 9rem;
  }
}

.footer__menu {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.footer__meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer__menu-item:hover {
  text-decoration: underline;
}

.footer__menu-item + .footer__menu-item {
  margin-top: 1rem;

  @media (--media-mobile) {
    margin-top: 0.5rem;
  }
}

.footer__social-media {
  display: flex;
  line-height: 1; /*always check focus styles*/
}

.footer__logo {
  margin-left: auto;

  @media (--media-sm-only) {
    margin-top: 2.25rem;
  }
}
