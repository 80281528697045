.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: var(--spacing);
  margin-top: var(--spacing-l);
}

a.button,
button.button {
  text-decoration: none;
  border: none;
  font-family: inherit;
  cursor: pointer;

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
}

.button {
  /* Display */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start; /* Optional - see "Gotchas" */

  /* Visual */
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--white);
  border-radius: 2.5rem;

  /* Size */
  padding: 00.667em 1.333em;
  min-width: 10ch;

  /* Text */
  text-align: center;
  line-height: 1.2;

  background: var(--green);

  position: relative;
  z-index: 2;

  /* all this before hack for transition effect */
  &::before {
    position: absolute;
    inset: 0;
    content: "";
    border-radius: 2.5rem;
    background-image: linear-gradient(90deg, #019ddc -3.83%, #37a79f 106.67%);
    z-index: -1;
    opacity: 0;
    transition: 220ms opacity ease-in-out;
  }

  &:hover::before,
  &:active::before,
  &:focus::before {
    opacity: 1;
  }

  transition: 220ms all ease-in-out;

  @media (--media-mobile) {
    font-size: 1rem;
  }
}

.button--undo {
  margin-left: auto;
  background-color: var(--blue);

  &:hover,
  &:active {
    background-color: var(--green);
  }

  &:focus {
    box-shadow: 0 0 0 4px var(--green);
  }
}

.button--block {
  width: 100%;
}
