:root {
  --black: #0d0d0d;
  --white: #fafafa;

  --blue: #009ddc;
  --green: #85b54a;

  --error-fg: rgb(192, 13, 12);

  --cell: 5.5rem;
  --cell-and-gap: 7rem;
  --cell-and-gap-percent: 8.48484848485%;

  --radius: 0.5rem;

  --spacing: 1.5rem;
  --spacing-m: 2rem;
  --spacing-l: 2.5rem;

  @media (--media-mobile) {
    --spacing: 0.75rem;
    --spacing-m: 1rem;
    --spacing-l: 1.25rem;
  }
}
