.gallery {
  --swiper-pagination-bottom: -1.5rem;
  --swiper-pagination-color: var(--blue);
  --swiper-pagination-bullet-inactive-color: var(--green);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 1rem;
  --swiper-pagination-bullet-height: 1rem;
  --swiper-navigation-top-offset: calc(50% - 1em);

  --swiper-navigation-size: 30px;
  --swiper-pagination-bullet-size: 9px;

  position: relative;
  aspect-ratio: 16 / 9;

  .swiper-pagination {
    text-align: left;
    position: relative;
  }

  @media (--media-mobile) {
    --swiper-pagination-bottom: -0.75rem;
    --swiper-pagination-bullet-width: 0.75rem;
    --swiper-pagination-bullet-height: 0.75rem;
  }
}

.swiper {
  .swiper-slide {
    height: auto;
    width: auto;
    max-width: 100%;
    display: none;
  }

  &.swiper-initialized {
    .swiper-slide {
      display: grid;
    }
  }

  .swiper-slide__img {
    display: inline-block;
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    height: auto;
    width: 100%;
  }

  .swiper-slide__video {
    width: 100%;
    height: auto;
  }

  .swiper-caption {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    color: var(--white);
    font-weight: bold;
    font-size: 4.5vw;
    padding-left: 0.5em;
    padding-top: 0.5em;
    line-height: 1.075;
    text-shadow: 0 0 5px #818181;

    a {
      color: var(--white);
      text-decoration: none;
    }

    span {
      text-decoration: underline;
    }

    @media (--media-sm-only) {
      font-size: 7vw;
    }
  }
}
