.hide {
  display: none !important;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;

  &.skip-link:focus-visible {
    height: auto;
    width: auto;
    left: 5rem;
    top: 5rem;
  }
}

.print-only {
  display: none;

  @media print {
    display: unset;
  }
}

:target {
  outline: 2px solid var(--black);
  outline-offset: max(2px, 0.1em);
  animation: targetFadeOut 2s forwards;
}

@keyframes targetFadeOut {
  0% {
    outline-color: transparent;
  }
  25% {
    outline-color: var(--black);
  }
  50% {
    outline-color: transparent;
  }
  75% {
    outline-color: var(--black);
  }
  100% {
    outline-color: transparent;
  }
}
