.cards {
  display: grid;
  gap: var(--spacing);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 23.5rem), 1fr));
}

.card {
  padding: var(--spacing);
  border-radius: var(--radius);
  box-shadow: 0px 0px 68px 24px rgba(126, 126, 126, 0.1);

  &:nth-child(1n) {
    background: linear-gradient(
      124deg,
      rgba(1, 157, 220, 0.25) 31.94%,
      rgba(43, 165, 172, 0.25) 52.43%
    );
  }

  &:nth-child(2n) {
    background: linear-gradient(
      153deg,
      rgba(43, 165, 172, 0.25) 37.68%,
      rgba(114, 178, 94, 0.25) 72.1%
    );
  }

  &:nth-child(3n) {
    background: linear-gradient(
      137deg,
      rgba(84, 172, 127, 0.2) 41.46%,
      rgba(133, 181, 74, 0.2) 72.94%
    );
  }
}
