@import "./custom-media.css";

.faq {
  margin-top: var(--spacing);

  .richtext {
    padding-top: 0;
  }
}

.faq__item {
  padding: var(--spacing-m);
  background: var(--white);
}

.faq__answer {
  padding-top: var(--spacing-m);

  > a {
    display: inline-block;
  }
}

.faq__item + .faq__item {
  margin-top: var(--spacing);
}

.faq__summary {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.faq__icon {
  flex-shrink: 0;
  display: grid;
  place-items: center;
  background-color: var(--green);
  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;
  border-radius: 50%;
  transition: transform 0.4s ease;
  transform: rotate(45deg);

  @media (--media-mobile) {
    width: 1.75rem;
    height: 1.75rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.faq__item[open] .faq__icon {
  transform: rotate(180deg);
}
