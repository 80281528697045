body {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      179deg,
      #fff -0.74%,
      rgba(232, 240, 221, 0.58) 26.28%,
      #fff 56.2%,
      rgba(187, 226, 240, 0.45) 91.91%
    ), linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 38.54%), #fff;
}

.container {
  width: 100%;
  max-width: calc(82.5rem + (2 * var(--spacing)));
  margin-left: auto;
  margin-right: auto;

  padding-left: var(--spacing);
  padding-right: var(--spacing);

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.main {
  flex: 1;
}

.richtext {
  max-width: 61.5625rem;

  padding-top: 9.5rem;

  @media (--media-mobile) {
    padding-top: 4.75rem;
  }
}

.cards {
  padding-top: 3.5rem;

  @media (--media-mobile) {
    padding-top: 1.75rem;
  }
}

.download {
  padding-top: 2.5rem;

  @media (--media-mobile) {
    padding-top: 1.25rem;
  }
}
