@import "./custom-media.css";

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Inter-Regular.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/Inter-Bold.woff2?v=3.19") format("woff2");
}

:root {
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: var(--black);
  font-size: 1rem;
  line-height: 1.618; /*uhu uhu the golden ratio*/
}

h1 {
  font-size: 3rem;
  line-height: 1.333;
}

h2 {
  font-size: 2rem;
  line-height: 1.25;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.25;
}

p {
  font-size: 1.125rem;
}

@media (--media-mobile) {
  .section {
    margin-bottom: 4.75rem;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 1.125;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
  }
}

.flow > * + * {
  margin-top: 1.5em;
}
