details {
  --_ms: 300ms;
  height: var(--collapsed);
  overflow: visible;
  transition: height var(--_ms) ease-out;
}

details[open] {
  height: var(--expanded);
}

summary {
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
}

details summary + * {
  transform: scaleY(0);
  transform-origin: top;
  pointer-events: none;
}

details[open] summary + * {
  animation: sweep var(--_ms) forwards;
  pointer-events: all;
}

@keyframes sweep {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
