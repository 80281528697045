.header {
  font-size: 1.125rem;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  place-items: center;
  gap: 3rem;
  padding: var(--spacing);

  @media (--media-sm-only) {
    gap: 1rem;
  }
}

.header__logo {
  margin-right: auto;
}

.logo {
  height: 3rem;
  width: auto;

  @media (--media-sm-only) {
    height: 2.5rem;
  }
}

.header__links {
  --_links_gap: 1.5rem;

  align-self: flex-end;
  margin-left: auto;
  display: flex;
  gap: var(--_links_gap);
  flex-wrap: wrap;
}

.header__menu {
  display: flex;
  align-items: flex-end;
}

.header__menu-item {
  &:hover {
    text-decoration: underline;
  }
}

.header__contact {
  display: flex;
  align-items: flex-end;
  gap: var(--_links_gap);
}

.header__email,
.header__phone {
  display: inline-flex;
}
