.form {
  counter-reset: summary;
  --_hp: 0.667em;

  font-size: 1.125rem;
  margin-left: var(--cell-and-gap-percent);
  margin-right: calc(2 * var(--cell-and-gap-percent));

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3em;

    margin: 1rem 0;
    margin-bottom: 1rem;

    color: var(--error-fg);
  }

  .errorlist {
    color: var(--error-fg);
  }

  .buttons {
    margin-bottom: var(--spacing-l);
  }

  @media (--media-mobile) {
    font-size: 1rem;
    margin-right: 0;
  }

  .richtext {
    margin-bottom: 1rem;
  }
}

.field {
  position: relative;

  & + .field,
  & + .richtext {
    padding-top: 2rem;
  }

  label {
    display: inline-block;
    margin-bottom: 1rem;
  }

  > label {
    font-weight: bold;
  }

  > label.required::after {
    content: " *";
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & select,
  & textarea,
  & .grow-wrap > textarea,
  & .grow-wrap::after {
    display: block;
    width: 100%;
    border: 1px solid;
    border-radius: 0.25rem;
    padding: 0.444em var(--_hp);
    background: #fff;
    line-height: 1.61;
  }
}

.field.is-invalid {
  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & select,
  & textarea {
    border-color: var(--error-fg);
    outline: var(--error-fg) 2px solid;
    outline-offset: 2px;
  }
}

.form__step {
  .richtext:first-child {
    padding-top: 0;
  }
}

.form__step--validated {
  input:invalid,
  select:invalid,
  textarea:invalid {
    border-color: var(--error-fg);
    outline: var(--error-fg) 2px solid;
    outline-offset: 2px;
  }
}

.widget--select select {
  appearance: none;
}

.field__help {
  padding: 0.25rem var(--_hp) 0;
}

.widget--radioselect div,
.widget--checkboxselectmultiple div {
  & label {
    position: relative;
    cursor: pointer;
    background-size: contain;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
  }

  & + div label {
    margin-top: 1rem;
  }
}

:root {
  --form-control-color: currentColor;
}

/* See https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
input[type="radio"] {
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  font-size: 1rem;
  line-height: 1;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid currentColor;
  border-radius: 50%;

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.1em) solid currentColor;
  outline-offset: max(2px, 0.1em);
}

/* https://moderncss.dev/pure-css-custom-checkbox-style/ */
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: inline-grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.widget--uploadfileinput {
  & label {
    position: static !important;
  }

  & input {
    margin: 0.5rem 0 0 0.25rem;
  }
}

.widget--hiddeninput {
  display: none;
}

.form__details {
  border-bottom: 1px solid var(--black);
}

.form__summary {
  cursor: pointer;
  display: flex;
  gap: var(--spacing);
  align-items: center;

  h2 {
    padding: 0;
    margin: 0;
    padding-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
  }

  &::before {
    --_dim: 3.75rem;

    counter-increment: summary;
    content: counter(summary);
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    display: grid;
    place-items: center;
    transition: transform 0.4s ease;
    width: var(--_dim);
    height: var(--_dim);
    border-radius: 50%;
    background-color: var(--blue);
    color: var(--white);
    margin-left: calc(-1 * var(--_dim) - var(--spacing));

    @media (--media-mobile) {
      --_dim: 1.875rem;

      font-size: 1.25rem;
      margin-left: calc(-1 * var(--_dim));
    }
  }
}

.form__details[open] .form__summary::before {
  transform: rotate(360deg);
}

.field.widget--recaptchav3 {
  display: none;
}
